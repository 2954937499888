import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import Page404 from "./pages/Page404";
import ProfilePage from "./pages/CommonPage";
// import CommonPage2 from "./pages/CommonPage2";
import Admin from "./pages/Admin";
import PowerBIPage from "./pages/PowerBIPage"; // Import PowerBIPage
import { LandingPage } from "./sections/auth/login/LandingPage";
import { RouteGuard } from "./auth/RouteGuard";
import HomeDashboard from "./pages/HomeDashboard";

// ----------------------------------------------------------------------
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../src/dashboard/theme";
import ReportPage from "./pages/ReportPage";
import CommonPage from "./pages/CommonPage";
import Contacts from "./dashboard/scenes/contacts";
import Calendar from "./dashboard/scenes/calendar/calendar";
import Geography from "./dashboard/scenes/geography";
// import Team from "./dashboard/scenes/team";
import ReportViewer from "./components/MIS/ReportViewer/ReportViewer";
import { useState } from "react";
import { FilterProvider } from "./context/FilterContext";
import GlobalFilters from "./components/MIS/FilterDropdowns/GlobalFilters";
// import PivotWithMultiLevelGrouping from "./components/MIS/ReportViewer/PivotTable";
// import ReactPivotTable from "./components/MIS/ReportViewer/ReactTable";
// import ReportTable from "./components/MIS/ReportViewer/ReactTable";
// import PivotWithFixedConfiguration from "./components/MIS/ReportViewer/PivotTable";
// import ReportTable from "./components/MIS/ReportViewer/ReportTable";

export default function Router() {
  const [theme, colorMode] = useMode(); // Use the custom hook to get the theme and color mode
  console.log("Theme:", theme); // Check if theme is defined
  const [reportData, setReportData] = useState([]); // Default value as an empty array

  const routes = useRoutes([
    {
      path: "/dashboard",
      element: (
        <RouteGuard>
          <DashboardLayout />
        </RouteGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true }, // Redirect to home by default
        {
          path: "admin",
          element: <Admin />,
        },
        {
          path: "MIS",
          element: <CommonPage />,
        },
        {
          path: "home",
          element: (
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <HomeDashboard />
                {/* <Contacts /> */}
                {/* <Calendar /> */}
                {/* <Geography /> */}
                {/* <Team /> */}
              </ThemeProvider>
            </ColorModeContext.Provider>
          ),
        },
        {
          path: "report",
          element: (
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                {/* <ReportProvider>
                  <GlobalFilters />
                  <Tabs />
                  <ReportTable />
                </ReportProvider> */}
                <FilterProvider>
                  <GlobalFilters />
                  <ReportViewer />
                </FilterProvider>
                {/* <PivotWithMultiLevelGrouping /> */}
                {/* <ReactPivotTable /> */}
                {/* <ReportTable /> */}
                {/* <MisReportPage /> */}

                {/* <PivotWithFixedConfiguration /> */}
              </ThemeProvider>
            </ColorModeContext.Provider>
          ),
          // element: <PivotTable />,
        },
        {
          path: "mock-dashboard",
          children: [
            {
              path: "home", // Home route
              element: (
                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <HomeDashboard />
                  </ThemeProvider>
                </ColorModeContext.Provider>
              ),
            },
            {
              path: "calendar", // Calendar route
              element: (
                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Calendar />
                  </ThemeProvider>
                </ColorModeContext.Provider>
              ),
            },
            {
              path: "grid", // Calendar route
              element: (
                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Contacts />
                  </ThemeProvider>
                </ColorModeContext.Provider>
              ),
            },
            {
              path: "Nationalities", // Calendar route
              element: (
                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <Geography />
                  </ThemeProvider>
                </ColorModeContext.Provider>
              ),
            },
          ],
        },
        // {
        //   path: "profile",
        //   // children: [{ element: <ProfilePage />, index: true }],
        //   element: <ProfilePage />,
        // },
        // {
        //   path: "powerbi",
        //   children: [{ element: <PowerBIPage />, index: true }],
        // },
        // {
        //   path: "mis/:reportName", // Route for MIS Reports
        //   element: <ReportPage />, // Your ReportPage component
        // },
        // {
        //   path: "testpage", // Route for MIS Reports
        //   element: <CommonPage />, // Your ReportPage component
        // },
      ],
    },
    // {
    //   path: "/mis",
    //   element: (
    //     <RouteGuard>
    //       <DashboardLayout />
    //     </RouteGuard>
    //   ),
    //   children: [
    //     {
    //       path: "fund-flow",
    //       element: (
    //         <FilterProvider>
    //           <GlobalFilters />
    //           {/* <ReportViewer /> */}
    //         </FilterProvider>
    //       ),
    //     },
    //     {
    //       path: "summary",
    //       element: (
    //         <FilterProvider>
    //           <GlobalFilters />
    //           {/* <ReportViewer /> */}
    //         </FilterProvider>
    //       ),
    //     },
    //     {
    //       path: "profit-&-loss",
    //       element: (
    //         <FilterProvider>
    //           <GlobalFilters />
    //           {/* <ReportViewer /> */}
    //         </FilterProvider>
    //       ),
    //     },
    //     {
    //       path: "fnb-consolidated",
    //       element: (
    //         <FilterProvider>
    //           <GlobalFilters />
    //           {/* <ReportViewer /> */}
    //         </FilterProvider>
    //       ),
    //     },
    //     {
    //       path: "departmental-report",
    //       element: (
    //         <FilterProvider>
    //           <GlobalFilters />
    //           {/* <ReportViewer /> */}
    //         </FilterProvider>
    //       ),
    //     },
    //   ],
    // },
    {
      path: "/mis",
      element: (
        <RouteGuard>
          <DashboardLayout />
        </RouteGuard>
      ),
      children: [
        {
          path: "fund-flow",
          element: (
            <FilterProvider reportName="fundFlow">
              <GlobalFilters />
              <ReportViewer />
            </FilterProvider>
          ),
        },
        {
          path: "summary",
          element: (
            <FilterProvider reportName="summary">
              <GlobalFilters />
              <ReportViewer />
            </FilterProvider>
          ),
        },
        {
          path: "profit-&-loss",
          element: (
            <FilterProvider reportName="PnL">
              <GlobalFilters />
              <ReportViewer />
            </FilterProvider>
          ),
        },
        {
          path: "fnb-consolidated",
          element: (
            <FilterProvider reportName="fbConsolidated">
              <GlobalFilters />
              <ReportViewer />
            </FilterProvider>
          ),
        },
        {
          path: "departmental-report",
          element: (
            <FilterProvider reportName="departmentalGroup">
              <GlobalFilters />
              <ReportViewer />
            </FilterProvider>
          ),
        },
      ],
    },

    {
      path: "/",
      element: (
        <RouteGuard>
          <LandingPage />
        </RouteGuard>
      ),
    },
    { path: "404", element: <Page404 /> },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
