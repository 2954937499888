import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../dashboard/theme";
import TransactionItem from "../dashboard/components/TransactionItem"; // Import the new component
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Bed, CurrencyRupee, HotelOutlined, People } from "@mui/icons-material";
import Header from "../dashboard/components/Header";
import LineChart from "../dashboard/components/LineChart";
import StatBox from "../dashboard/components/StatBox";
import PieChart from "../dashboard/components/PieChart";
import {
  businessSegmentData,
  marketSegmentData,
  mockRoomsData,
  mockNationalities,
} from "../dashboard/data/mockData";
// import CombinedChart from "../../";
import CombinedChart from "../dashboard/components/CombinedChart";

const HomeDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      {/* HEADER */}
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box> */}
      <Box
        display="flex"
        flexDirection={{
          xs: "column", // Stack vertically on mobile
          sm: "row", // Horizontal on small and larger screens
        }}
        justifyContent="space-between"
        alignItems="center"
        gap="20px" // Added gap between the header and button
      >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            // Ensure the button doesn't take too much width on small screens
            justifyContent: { xs: "center", sm: "flex-end" }, // Center on mobile, align right on desktop
          }}
        >
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: {
                xs: "12px", // Smaller font size on mobile
                sm: "14px", // Default font size on tablets and larger
              },
              fontWeight: "bold",
              padding: {
                xs: "8px 16px", // Smaller padding on mobile
                sm: "10px 20px", // Default padding on tablets and larger
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <DownloadOutlinedIcon
              sx={{ mr: "8px", fontSize: { xs: "20px", sm: "24px" } }}
            />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        // gridTemplateColumns="repeat(12, 1fr)"
        gridTemplateColumns={{
          xs: "repeat(5, 1fr)", // Mobile: 6 columns
          sm: "repeat(10, 1fr)", // Tablet and larger: 12 columns
        }}
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          // gridColumn="span 3"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 2", // Tablet and larger: 3 columns
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="12,361"
            subtitle="No of Rooms Available"
            progress="0.75"
            increase="+14%"
            icon={
              <HotelOutlined
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          // gridColumn="span 3"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 2", // Tablet and larger: 3 columns
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="431,225"
            subtitle="No of Rooms Occupied"
            progress="0.50"
            increase="+21%"
            icon={
              <Bed sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />
            }
          />
        </Box>
        <Box
          // gridColumn="span 3"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 2", // Tablet and larger: 3 columns
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="32,441"
            subtitle="ARR"
            progress="0.30"
            increase="+5%"
            icon={
              <CurrencyRupee
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          // gridColumn="span 3"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 2", // Tablet and larger: 3 columns
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Occupancy"
            progress="0.80"
            increase="+43%"
            icon={
              <People
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          // gridColumn="span 3"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 2", // Tablet and larger: 3 columns
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="RevPar"
            progress="0.80"
            increase="+43%"
            icon={
              <CurrencyRupee
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          // gridColumn="span 8"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 6", // Tablet and larger: 8 columns
          }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                ₹59,342.32
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
            {/* <CombinedChart isDashboard={true} /> */}
          </Box>
        </Box>
        <Box
          // gridColumn="span 4"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 4", // Tablet and larger: 4 columns
          }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Room Category
            </Typography>
          </Box>
          {mockRoomsData.map((transaction, i) => (
            <TransactionItem
              key={`${transaction.txId}-${i}`}
              transaction={transaction}
              colors={colors}
            />
          ))}
        </Box>

        {/* ROW 3 */}
        <Box
          // gridColumn="span 4"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 5", // Tablet and larger: 4 columns
          }}
          gridRow={{
            xs: "span 2", // Mobile: 6 columns
            sm: "span 3", // Tablet and larger: 4 columns
          }}
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Business Segement
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
            height="100%"
          >
            {/* <ProgressCircle size="125" /> */}
            <PieChart data={businessSegmentData} />
            {/* <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              ₹48,352 revenue generated
            </Typography> */}
          </Box>
        </Box>
        <Box
          // gridColumn="span 4"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 5", // Tablet and larger: 4 columns
          }}
          gridRow={{
            xs: "span 2", // Mobile: 6 columns
            sm: "span 3", // Tablet and larger: 4 columns
          }}
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Market Segement
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
            height="100%"
          >
            {/* <ProgressCircle size="125" /> */}
            <PieChart data={marketSegmentData} />
            {/* <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              ₹48,352 revenue generated
            </Typography> */}
          </Box>
        </Box>
        {/* <Box
          // gridColumn="span 4"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 6", // Tablet and larger: 4 columns
          }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Food type
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box> */}
        <Box
          // gridColumn="span 4"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 4", // Tablet and larger: 4 columns
          }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Nationalities
            </Typography>
          </Box>
          {mockNationalities.map((transaction, i) => (
            <TransactionItem
              key={`${transaction.txId}-${i}`}
              transaction={transaction}
              colors={colors}
            />
          ))}
        </Box>
        {/* ROW 4 */}
        <Box
          // gridColumn="span 8"
          gridColumn={{
            xs: "span 5", // Mobile: 6 columns
            sm: "span 6", // Tablet and larger: 4 columns
          }}
          // gridRow="span 3"
          gridRow={{
            xs: "span 2", // Mobile: 6 columns
            sm: "span 2", // Tablet and larger: 4 columns
          }}
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Income & Expense
              </Typography>
              {/* <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                ₹59,342.32
              </Typography> */}
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="100%" m="-20px 0 0 0">
            <CombinedChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeDashboard;
