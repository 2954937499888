import { useState } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import StatBox from "../../../dashboard/components/StatBox";
import {
  LogoutOutlined,
  PersonAddAltOutlined,
  CancelOutlined,
  LocalHotelOutlined,
  LoginOutlined,
} from "@mui/icons-material";

// Sample Room Booking Data
const roomBookings = [
  {
    date: "2024-10-01",
    totalRooms: 10,
    bookedRooms: 8,
    canceledRooms: 1,
    arrivals: 4,
    checkIn: 7,
    checkOut: 2,
  },
  {
    date: "2024-10-02",
    totalRooms: 12,
    bookedRooms: 10,
    canceledRooms: 0,
    arrivals: 5,
    checkIn: 8,
    checkOut: 3,
  },
  {
    date: "2024-10-03",
    totalRooms: 10,
    bookedRooms: 6,
    canceledRooms: 2,
    arrivals: 3,
    checkIn: 4,
    checkOut: 1,
  },
  {
    date: "2024-10-04",
    totalRooms: 8,
    bookedRooms: 6,
    canceledRooms: 1,
    arrivals: 4,
    checkIn: 5,
    checkOut: 2,
  },
  {
    date: "2024-10-05",
    totalRooms: 15,
    bookedRooms: 12,
    canceledRooms: 3,
    arrivals: 6,
    checkIn: 9,
    checkOut: 4,
  },
  {
    date: "2024-10-10",
    totalRooms: 10,
    bookedRooms: 7,
    canceledRooms: 1,
    arrivals: 4,
    checkIn: 6,
    checkOut: 2,
  },
  {
    date: "2024-10-15",
    totalRooms: 14,
    bookedRooms: 11,
    canceledRooms: 2,
    arrivals: 5,
    checkIn: 8,
    checkOut: 6,
  },
  {
    date: "2024-10-20",
    totalRooms: 15,
    bookedRooms: 10,
    canceledRooms: 3,
    arrivals: 7,
    checkIn: 6,
    checkOut: 4,
  },
  {
    date: "2024-10-22",
    totalRooms: 20,
    bookedRooms: 18,
    canceledRooms: 1,
    arrivals: 10,
    checkIn: 15,
    checkOut: 8,
  },
  {
    date: "2024-10-25",
    totalRooms: 12,
    bookedRooms: 10,
    canceledRooms: 1,
    arrivals: 5,
    checkIn: 7,
    checkOut: 3,
  },
  {
    date: "2024-10-28",
    totalRooms: 10,
    bookedRooms: 9,
    canceledRooms: 0,
    arrivals: 5,
    checkIn: 7,
    checkOut: 3,
  },
  {
    date: "2024-10-30",
    totalRooms: 10,
    bookedRooms: 8,
    canceledRooms: 2,
    arrivals: 4,
    checkIn: 6,
    checkOut: 2,
  },
];

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);

  const handleDateClick = (selected) => {
    const title = prompt("Please enter a new title for your event");
    const calendarApi = selected.view.calendar;
    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: `${selected.dateStr}-${title}`,
        title,
        start: selected.startStr,
        end: selected.endStr,
        allDay: selected.allDay,
      });
    }
  };

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${selected.event.title}'`
      )
    ) {
      selected.event.remove();
    }
  };

  // Convert room bookings into calendar events
  const generateEvents = () => {
    return roomBookings.map((booking) => ({
      id: booking.date,
      title: `Bookings: ${booking.bookedRooms}\nCanceled: ${booking.canceledRooms}\nArrivals: ${booking.arrivals}\nCheck-ins: ${booking.checkIn}\nCheck-outs: ${booking.checkOut}`,
      start: booking.date,
      allDay: true,
    }));
  };

  // Calculate totals
  const calculateTotals = () => {
    const totals = {
      totalBookings: 0,
      totalCancellations: 0,
      totalArrivals: 0,
      totalCheckIns: 0,
      totalCheckOuts: 0,
    };

    roomBookings.forEach((booking) => {
      totals.totalBookings += booking.bookedRooms;
      totals.totalCancellations += booking.canceledRooms;
      totals.totalArrivals += booking.arrivals;
      totals.totalCheckIns += booking.checkIn;
      totals.totalCheckOuts += booking.checkOut;
    });

    return totals;
  };

  const totals = calculateTotals();

  // Summary data
  const summaryData = [
    {
      title: totals.totalBookings,
      subtitle: "Bookings",
      progress: "0.75",
      increase: "+14%",
      icon: (
        <LocalHotelOutlined
          sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        />
      ),
    },
    {
      title: totals.totalCancellations,
      subtitle: "Cancellations",
      progress: (totals.totalCancellations / totals.totalBookings).toFixed(2),
      increase: "+5%",
      icon: (
        <CancelOutlined
          sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        />
      ),
    },
    {
      title: totals.totalArrivals,
      subtitle: "Arrivals",
      progress: (totals.totalArrivals / totals.totalBookings).toFixed(2),
      increase: "+10%",
      icon: (
        <PersonAddAltOutlined
          sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        />
      ),
    },
    {
      title: totals.totalCheckIns,
      subtitle: "Check-ins",
      progress: (totals.totalCheckIns / totals.totalBookings).toFixed(2),
      increase: "+8%",
      icon: (
        <LoginOutlined
          sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        />
      ),
    },
    {
      title: totals.totalCheckOuts,
      subtitle: "Check-outs",
      progress: (totals.totalCheckOuts / totals.totalBookings).toFixed(2),
      increase: "+3%",
      icon: (
        <LogoutOutlined
          sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        />
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Calendar" subtitle="Full Calendar with Room Bookings" />

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        // gridTemplateColumns="repeat(12, 1fr)"
        gridTemplateColumns={{
          xs: "repeat(5, 1fr)", // Mobile: 6 columns
          sm: "repeat(10, 1fr)", // Tablet and larger: 12 columns
        }}
        gridAutoRows="140px"
        gap="20px"
      >
        {summaryData.map((data, index) => (
          <Box
            key={index}
            gridColumn={{
              xs: "span 5", // Mobile: 6 columns
              sm: "span 2", // Tablet and larger: 3 columns
            }}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginBottom={"1rem"}
          >
            <StatBox
              title={data.title}
              subtitle={data.subtitle}
              progress={data.progress}
              increase={data.increase}
              icon={data.icon}
            />
          </Box>
        ))}
      </Box>

      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        {/* <Box
          flex="1 1 20%"
          backgroundColor={colors.primary[400]}
          p="15px"
          borderRadius="4px"
          overflow="auto" // Enables scrolling
          maxHeight="75vh" // Fixed height for scrolling
        >
          <Typography variant="h5">Events</Typography>
          <List>
            {currentEvents.map((event) => (
              <ListItem
                key={event.id}
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  margin: "10px 0",
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={event.title}
                  secondary={
                    <Typography>
                      {formatDate(event.start, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box> */}

        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              // listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => setCurrentEvents(events)}
            initialEvents={generateEvents()} // Add events based on room bookings
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
