import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { useState } from "react";

// @mui
import {
  Box,
  List,
  ListItemText,
  Collapse,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], onCloseNav }) {
  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} onCloseNav={onCloseNav} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item, onCloseNav }) {
  const { title, path, icon, isAdmin, children } = item;
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  if (children) {
    // Handle items with children (collapsible menu)
    return (
      <>
        <ListItemButton onClick={handleToggle}>
          <ListItemIcon>{icon && icon}</ListItemIcon>
          <ListItemText primary={title} />
          {isAdmin && (
            <StyledNavItemIcon
              sx={{ scale: "0.8", color: "#1976d2", opacity: "0.6" }}
            >
              {isAdmin}
            </StyledNavItemIcon>
          )}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child) => (
              <StyledNavItem
                key={child.title}
                component={RouterLink}
                to={child.path}
                onClick={onCloseNav}
                sx={{
                  pl: 4, // Indentation for sub-items
                  "&.active": {
                    color: "text.primary",
                    bgcolor: "action.selected",
                    fontWeight: "fontWeightBold",
                  },
                }}
              >
                <ListItemText disableTypography primary={child.title} />
              </StyledNavItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  // Handle regular nav item (no children)
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      onClick={onCloseNav}
      sx={{
        "&.active": {
          color: "text.primary",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {isAdmin && (
        <StyledNavItemIcon
          sx={{ scale: "0.8", color: "#1976d2", opacity: "0.6" }}
        >
          {isAdmin}
        </StyledNavItemIcon>
      )}
    </StyledNavItem>
  );
}
