import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

// Mock data (replace with actual data)
const data = [
  {
    outlet: "IRA Mumbai",
    income: 120,
    expense: 80,
  },
  {
    outlet: "IRA Pune",
    income: 90,
    expense: 50,
  },
  {
    outlet: "Orchid Pune",
    income: 150,
    expense: 110,
  },
  {
    outlet: "Orchid Mumbai",
    income: 130,
    expense: 95,
  },
];

const CombinedChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Extract data for the line chart (income - expense = profit margin)
  const lineData = data.map((item) => ({
    outlet: item.outlet,
    profit: item.income - item.expense,
  }));

  // Check profit data for debugging
  console.log("Line Data:", lineData);

  return (
    <div style={{ height: "100%" }}>
      <ResponsiveBar
        data={data}
        keys={["income", "expense"]}
        indexBy="outlet"
        margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
        padding={0.3}
        colors={{ scheme: "nivo" }}
        enableLabel={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Amount (₹)",
          legendPosition: "middle",
          legendOffset: -40,
          domain: [
            0,
            Math.max(...data.map((d) => Math.max(d.income, d.expense))) + 20,
          ], // Adjust to cover both income and expense
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Outlet",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        layers={[
          "grid",
          "axes",
          "bars",
          // Custom layer to draw the line chart above the bars
          ({ xScale, yScale, bars }) => {
            const linePoints = bars.map((bar) => {
              const index = data.findIndex(
                (item) => item.outlet === bar.indexValue
              );
              const profitValue = index !== -1 ? lineData[index].profit : 0; // Default to 0 if not found
              const x = bar.x + bar.width / 2; // Middle of the bar
              const y = yScale(profitValue); // Y position based on profit

              // Debugging: Log each point's values
              console.log(
                `Bar: ${bar.indexValue}, Profit: ${profitValue}, Point:`,
                { x, y }
              );

              return { x, y, profitValue }; // Include profitValue for logging
            });

            const validPoints = linePoints.filter(
              (point) => !isNaN(point.x) && !isNaN(point.y)
            );

            // Return nothing if we can't draw the line
            if (validPoints.length < 2) return null;

            return (
              <g>
                {/* Draw line */}
                <path
                  d={`M${validPoints.map((p) => `${p.x},${p.y}`).join(" L")}`}
                  fill="none"
                  stroke={colors.greenAccent[500]}
                  strokeWidth={2}
                  strokeLinecap="round"
                />
                {/* Draw points */}
                {validPoints.map((p, index) => (
                  <circle
                    key={index}
                    cx={p.x}
                    cy={p.y}
                    r={4}
                    fill={colors.greenAccent[500]}
                    stroke="white"
                    strokeWidth={2}
                  />
                ))}
              </g>
            );
          },
        ]}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
        }}
      />
    </div>
  );
};

export default CombinedChart;
