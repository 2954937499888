import styled from "styled-components";

const Styles = styled.div`
  padding: 1rem;

  .table-container {
    overflow-x: auto;
    overflow-y: auto;
    text-transform: uppercase;

    -webkit-overflow-scrolling: touch;
    max-height: 600px; /* Set a fixed maximum height for vertical scrolling */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  table {
    border-spacing: 0;
    width: 100%;
    min-width: 1200px;
    font-family: "Roboto", sans-serif;
    text-align: left;
    border-collapse: collapse;

    th,
    td {
      padding: 12px 15px;
      border: 1px solid #e0e0e0;
      text-align: left;
      white-space: nowrap;
    }

    th {
      background-color: #f5f5f5;
      font-weight: bold;
      color: #555;
      text-transform: capitalize;
      position: sticky;
      z-index: 2;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      top: 0;
      cursor: pointer; /* Indicates interactivity */
      transition: background-color 0.2s ease-in-out;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th:hover {
      background-color: #e9ecef; /* Slight hover effect for headers */
    }

    th[data-tooltip]::after {
      content: attr(data-tooltip);
      position: absolute;
      top: 120%;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px 10px;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      font-size: 0.85rem;
      border-radius: 4px;
      white-space: nowrap;
      opacity: 0;
      pointer-events: none;
      z-index: 100;
      transition: opacity 0.2s;
    }

    th[data-tooltip]:hover::after {
      opacity: 1;
    }

    td {
      color: #444;
      font-size: 0.95rem;
      transition: background-color 0.2s ease-in-out;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:nth-child(odd) {
      background-color: #ffffff;
    }

    tr:hover td {
      background-color: #eaf6ff;
    }

    /* Highlight the column on cell hover */
    td:hover {
      background-color: #eaf6ff;
    }

    th:hover {
      background-color: #e9ecef;
    }

    .sticky-total {
      position: sticky;
      right: 0;
      background-color: #e8f5e9;
      z-index: 1;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
      font-weight: bold;
      color: #1b5e20;
    }

    .sticky-total-header {
      position: sticky;
      right: 0;
      background-color: #2e7d32;
      color: #ffffff;
      z-index: 3;
      box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
      font-weight: bold;
      text-align: center;
    }

    .group-toggle {
      cursor: pointer;
      margin-right: 5px;
    }

    /* Sticky base columns (row group columns) */
    .sticky-column {
      position: sticky;
      left: 0;
      background-color: #ffffff;
      z-index: 1;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    }

    .sticky-group {
      position: sticky;
      left: 0;
      background-color: #ffffff;
      z-index: 1;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    }
  }

  /* Scrollbar styling */
  .table-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .table-container::-webkit-scrollbar-thumb {
    background-color: #00796b;
    border-radius: 4px;
  }

  .table-container::-webkit-scrollbar-thumb:hover {
    background-color: #004d40;
  }

  .table-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Add subtle hover effect for better interactivity */
  table tr:hover td {
    background-color: #f1f9ff;
  }

  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    table {
      font-size: 0.85rem;
    }

    th,
    td {
      padding: 10px;
    }

    .table-container {
      max-height: 400px;
    }
  }
`;

export default Styles;
