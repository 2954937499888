import React, { useMemo } from "react";
import { useTable, useGroupBy, useSortBy, useExpanded } from "react-table";
import Styles from "./StyledComponent"; // Ensure this path is correct

/**
 * Formats value to Indian Rupees (INR)
 * @param {number} value - The value to be formatted.
 * @returns {string} - The formatted INR value.
 */
const formatToINR = (value) => {
  if (typeof value !== "number") return "₹0.00"; // Handle non-numeric values
  return `₹${value.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const formatColumnName = (columnName) => {
  if (!columnName) return "";
  return columnName.replace(/_/g, " ").toUpperCase(); // Remove underscores and capitalize
};

/**
 * Processes the input data to generate rows and columns for the table.
 * @param {Array} data - The dataset to be processed.
 * @param {Array} rowGroup - Fields for row grouping.
 * @param {Array} rowGroupSortKeys - Sort keys for row grouping.
 * @param {String} aggregatedValue - The field to aggregate.
 * @param {String} aggregationType - Aggregation type ('sum', 'avg', etc.).
 * @param {Array} columnGroup - Fields for dynamic column grouping.
 * @returns {Object} - Processed rows and columns.
 */

// const processData = (
//   data,
//   rowGroup,
//   rowGroupSortKeys,
//   aggregatedValue,
//   aggregationType,
//   columnGroup,
//   reportName
// ) => {
//   if (!Array.isArray(data)) {
//     console.error("Invalid data format. Expected an array of objects.");
//     return { rows: [], columns: [] };
//   }

//   // Step 1: Sort the data
//   const sortedData = [...data].sort((a, b) => {
//     for (let key of rowGroupSortKeys) {
//       const comparison = a[key]?.localeCompare(b[key]);
//       if (comparison !== 0) return comparison;
//     }
//     return 0;
//   });

//   // Initialize data structures
//   const groupedRows = {};
//   const rows = [];
//   const totalIncomeByDate = {};
//   const totalExpenditureByDate = {};
//   const uniqueCycleDates = new Set();

//   // Step 2: Process data and group values
//   sortedData.forEach((item) => {
//     const rowKey = rowGroup.map((field) => item[field]).join("-");
//     const columnField = columnGroup.map((key) => item[key]).join("-");
//     const value = item[aggregatedValue] || 0;

//     uniqueCycleDates.add(columnField);

//     // Initialize the group if it doesn't exist
//     if (!groupedRows[rowKey]) {
//       groupedRows[rowKey] = rowGroup.reduce(
//         (acc, field) => ({ ...acc, [field]: item[field] }),
//         { total: 0 }
//       );
//     }

//     // Aggregate values
//     groupedRows[rowKey][columnField] =
//       aggregationType === "sum"
//         ? (groupedRows[rowKey][columnField] || 0) + value
//         : aggregationType === "avg"
//         ? ((groupedRows[rowKey][columnField] || 0) + value) / 2
//         : value;

//     // Update row total
//     groupedRows[rowKey].total += value;

//     // Track income and expenditure
//     if (item["GROUP_CLASS"] === "Income") {
//       totalIncomeByDate[columnField] =
//         (totalIncomeByDate[columnField] || 0) + value;
//     } else if (item["GROUP_CLASS"] === "Expenditure") {
//       totalExpenditureByDate[columnField] =
//         (totalExpenditureByDate[columnField] || 0) + value;
//     }
//   });

//   rows.push(...Object.values(groupedRows));

//   // Step 3: Compute GOP and GOP%
//   const GOPRow = { GROUP_CLASS: "GOP", total: 0 };
//   const GOPPercentageRow = { GROUP_CLASS: "GOP%", total: 0 };

//   Array.from(uniqueCycleDates).forEach((cycleDate) => {
//     const income = totalIncomeByDate[cycleDate] || 0;
//     const expenditure = totalExpenditureByDate[cycleDate] || 0;
//     const GOP = income - expenditure;
//     const GOPPercentage = income !== 0 ? (GOP / income) * 100 : 0;

//     GOPRow[cycleDate] = GOP;
//     GOPRow.total += GOP;

//     GOPPercentageRow[cycleDate] = GOPPercentage;
//     GOPPercentageRow.total += GOPPercentage;
//   });

//   if (reportName !== "fbConsolidated") {
//     rows.push(GOPRow);
//     rows.push(GOPPercentageRow);
//   }

//   // Step 4: Build dynamic columns
//   const buildColumns = (fields, dataSet, parentAccessor = "") => {
//     if (!fields.length) return null;

//     const [currentField, ...remainingFields] = fields;
//     const uniqueValues = [
//       ...new Set(dataSet.map((item) => item[currentField])),
//     ];

//     return uniqueValues.map((value) => {
//       const accessor = parentAccessor
//         ? `${parentAccessor}-${value}`
//         : value.toString();

//       if (remainingFields.length > 0) {
//         return {
//           Header: value,
//           columns: buildColumns(
//             remainingFields,
//             dataSet.filter((item) => item[currentField] === value),
//             accessor
//           ),
//         };
//       }

//       return {
//         Header: value,
//         accessor,
//         aggregate: aggregationType,
//         Aggregated: ({ value }) => formatToINR(value || 0),
//       };
//     });
//   };

//   const dynamicColumns = buildColumns(columnGroup, sortedData);

//   const totalColumn = {
//     Header: "Total",
//     accessor: "total",
//     Aggregated: ({ value }) => formatToINR(value || 0),
//     aggregate: (leafValues) => leafValues.reduce((sum, val) => sum + val, 0),
//   };

//   return {
//     rows,
//     columns: [
//       ...rowGroup.map((field) => ({
//         Header: formatColumnName(field),
//         accessor: field,
//       })),
//       ...(dynamicColumns || []),
//       totalColumn,
//     ],
//   };
// };

const processData = (
  data,
  rowGroup,
  rowGroupSortKeys,
  aggregatedValue,
  aggregationType,
  columnGroup,
  reportName
) => {
  if (!Array.isArray(data)) {
    console.error("Invalid data format. Expected an array of objects.");
    return { rows: [], columns: [] };
  }

  // Step 1: Sort the data
  const sortedData = [...data].sort((a, b) => {
    for (let key of rowGroupSortKeys) {
      const comparison = a[key]?.localeCompare(b[key]);
      if (comparison !== 0) return comparison;
    }
    return 0;
  });

  // Initialize data structures
  const groupedRows = {};
  const rows = [];
  const totalIncomeByDate = {};
  const totalExpenditureByDate = {};
  const uniqueCycleDates = new Set();

  // Step 2: Process data and group values
  sortedData.forEach((item) => {
    const rowKey = rowGroup.map((field) => item[field]).join("-");
    const columnField = columnGroup.map((key) => item[key]).join("-");
    const value = item[aggregatedValue] || 0;

    uniqueCycleDates.add(columnField);

    // Initialize the group if it doesn't exist
    if (!groupedRows[rowKey]) {
      groupedRows[rowKey] = rowGroup.reduce(
        (acc, field) => ({ ...acc, [field]: item[field] }),
        { total: 0 }
      );
    }

    // Aggregate values
    groupedRows[rowKey][columnField] =
      aggregationType === "sum"
        ? (groupedRows[rowKey][columnField] || 0) + value
        : aggregationType === "avg"
        ? ((groupedRows[rowKey][columnField] || 0) + value) / 2
        : value;

    // Update row total
    groupedRows[rowKey].total += value;

    // Track income and expenditure
    if (item["GROUP_CLASS"] === "Income") {
      totalIncomeByDate[columnField] =
        (totalIncomeByDate[columnField] || 0) + value;
    } else if (item["GROUP_CLASS"] === "Expenditure") {
      totalExpenditureByDate[columnField] =
        (totalExpenditureByDate[columnField] || 0) + value;
    }
  });

  rows.push(...Object.values(groupedRows));

  // Step 3: Compute Profit/Loss or GOP and GOP%
  if (reportName === "fbConsolidated" || reportName === "departmentalGroup") {
    // Profit/Loss Row
    const profitLossRow = { GROUP_CLASS: "PROFIT/LOSS", total: 0 };

    Array.from(uniqueCycleDates).forEach((cycleDate) => {
      const income = totalIncomeByDate[cycleDate] || 0;
      const expenditure = totalExpenditureByDate[cycleDate] || 0;
      const profitLoss = income - expenditure;

      profitLossRow[cycleDate] = profitLoss;
      profitLossRow.total += profitLoss;
    });

    rows.push(profitLossRow);
  } else {
    // GOP Row
    const GOPRow = { GROUP_CLASS: "GOP", total: 0 };
    const GOPPercentageRow = { GROUP_CLASS: "GOP%", total: 0 };

    Array.from(uniqueCycleDates).forEach((cycleDate) => {
      const income = totalIncomeByDate[cycleDate] || 0;
      const expenditure = totalExpenditureByDate[cycleDate] || 0;
      const GOP = income - expenditure;
      const GOPPercentage = income !== 0 ? (GOP / income) * 100 : 0;

      GOPRow[cycleDate] = GOP;
      GOPRow.total += GOP;

      GOPPercentageRow[cycleDate] = GOPPercentage;
      GOPPercentageRow.total += GOPPercentage;
    });

    rows.push(GOPRow);
    rows.push(GOPPercentageRow);
  }

  // Step 4: Build dynamic columns
  const buildColumns = (fields, dataSet, parentAccessor = "") => {
    if (!fields.length) return null;

    const [currentField, ...remainingFields] = fields;
    const uniqueValues = [
      ...new Set(dataSet.map((item) => item[currentField])),
    ];

    return uniqueValues.map((value) => {
      const accessor = parentAccessor
        ? `${parentAccessor}-${value}`
        : value.toString();

      if (remainingFields.length > 0) {
        return {
          Header: value,
          columns: buildColumns(
            remainingFields,
            dataSet.filter((item) => item[currentField] === value),
            accessor
          ),
        };
      }

      return {
        Header: value,
        accessor,
        aggregate: aggregationType,
        Aggregated: ({ value }) => formatToINR(value || 0),
      };
    });
  };

  const dynamicColumns = buildColumns(columnGroup, sortedData);

  const totalColumn = {
    Header: "Total",
    accessor: "total",
    Aggregated: ({ value }) => formatToINR(value || 0),
    aggregate: (leafValues) => leafValues.reduce((sum, val) => sum + val, 0),
  };

  return {
    rows,
    columns: [
      ...rowGroup.map((field) => ({
        Header: formatColumnName(field),
        accessor: field,
      })),
      ...(dynamicColumns || []),
      totalColumn,
    ],
  };
};

const DynamicAggregationTable = ({
  reportData,
  rowGroup,
  rowGroupSortKeys,
  aggregatedValue,
  aggregationType,
  columnGroup,
  title = "Title",
  subTitle = "Subtitle",
  reportName,
}) => {
  const { rows, columns } = useMemo(
    () =>
      processData(
        reportData,
        rowGroup,
        rowGroupSortKeys,
        aggregatedValue,
        aggregationType,
        columnGroup,
        reportName
      ),
    [
      reportData,
      rowGroup,
      rowGroupSortKeys,
      aggregatedValue,
      aggregationType,
      columnGroup,
      reportName,
    ]
  );

  const groupByLevels = rowGroup.slice(0, -1);
  const tableInstance = useTable(
    {
      columns,
      data: rows,
      initialState: {
        groupBy: groupByLevels,
      },
    },
    useGroupBy,
    useSortBy,
    useExpanded
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: tableRows,
    prepareRow,
    toggleAllRowsExpanded,
  } = tableInstance;

  // Prevent expanding GOP and GOP% rows
  const handleExpandCollapseAll = (expand) => {
    tableRows.forEach((row) => {
      if (
        row.values.GROUP_CLASS !== "GOP" &&
        row.values.GROUP_CLASS !== "GOP%" &&
        row.values.GROUP_CLASS !== "PROFIT/LOSS"
      ) {
        row.toggleRowExpanded(expand);
      }
    });
  };

  const totalColumnCount = columns.reduce((total, column) => {
    if (column.columns) {
      return (
        total +
        column.columns.reduce(
          (subTotal, subCol) =>
            subTotal + (subCol.columns ? subCol.columns.length : 1),
          0
        )
      );
    }
    return total + 1;
  }, 0);

  // // Utility function to conditionally format data
  // const formatCellValue = (value) => {
  //   if (typeof value === "number") {
  //     return formatToINR(value); // Apply INR formatting to numbers
  //   }
  //   return value || ""; // Leave empty or non-numeric cells unchanged
  // };

  // Utility function to conditionally format data
  const formatCellValue = (value, isGOPPercentage = false) => {
    if (typeof value === "number") {
      if (isGOPPercentage) {
        // Format as percentage for GOP% rows
        return `${value.toFixed(2)}%`;
      }
      // Apply INR formatting for other numeric values
      return formatToINR(value);
    }
    return value || ""; // Leave empty or non-numeric cells unchanged
  };

  return (
    <Styles>
      <div className="table-container">
        {/* Expand/Collapse All Buttons */}
        <div className="expand-collapse-buttons">
          <button onClick={() => handleExpandCollapseAll(true)}>
            Expand All
          </button>
          <button onClick={() => handleExpandCollapseAll(false)}>
            Collapse All
          </button>
        </div>
        <table {...getTableProps()}>
          <thead className="scrollable-header">
            {/* Title Row */}
            <tr>
              <th
                colSpan={totalColumnCount}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  backgroundColor: "#f1f1f1",
                  padding: "10px",
                }}
              >
                {title} ({subTitle})
              </th>
            </tr>
            {/* Column Headers */}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    colSpan={
                      column.columns?.reduce(
                        (total, subCol) =>
                          total + (subCol.columns ? subCol.columns.length : 1),
                        0
                      ) || 1
                    }
                    className={
                      column.id === "total"
                        ? "sticky-total-header"
                        : rowGroup.includes(column.id)
                        ? "sticky-column"
                        : ""
                    }
                    data-tooltip={column.render("Header")}
                  >
                    {column.render("Header")}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {tableRows.map((row) => {
              prepareRow(row);

              const isGOPRow = row.values.GROUP_CLASS === "GOP"; // Check if the row is a GOP row
              const isGOPPercentageRow = row.values.GROUP_CLASS === "GOP%"; // Check if the row is a GOP % row
              const isProfitLossRow = row.values.GROUP_CLASS === "PROFIT/LOSS";

              return (
                <tr
                  {...row.getRowProps()}
                  style={
                    isGOPRow || isGOPPercentageRow || isProfitLossRow
                      ? {
                          position: "sticky",
                          top: 0,
                          background: "#fff",
                          zIndex: 2,
                        }
                      : {}
                  }
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={
                        cell.column.id === "total"
                          ? "sticky-total"
                          : rowGroup.includes(cell.column.id)
                          ? "sticky-column"
                          : ""
                      }
                    >
                      {isGOPRow || isGOPPercentageRow || isProfitLossRow ? (
                        // For GOP and GOP% rows, apply formatCellValue
                        <span className="aggregated">
                          {formatCellValue(cell.value, isGOPPercentageRow)}{" "}
                          {/* Apply INR formatting */}
                        </span>
                      ) : cell.isGrouped ? (
                        row.values.isExpandable === false ? (
                          <>{cell.render("Cell")}</> // No expand/collapse for GOP rows
                        ) : (
                          <>
                            <span
                              {...row.getToggleRowExpandedProps()}
                              className="group-toggle"
                              role="button"
                              aria-expanded={row.isExpanded}
                            >
                              {row.isExpanded ? "🔽" : "▶"}
                            </span>
                            {cell.render("Cell")}
                          </>
                        )
                      ) : cell.isAggregated ? (
                        <span className="aggregated">
                          {cell.render("Aggregated")}
                          {/* {formatCellValue(
                            cell.value,
                            isGOPPercentageRow // Pass the isGOPPercentage flag for formatting
                          )} */}
                        </span>
                      ) : row.depth === rowGroup.length - 1 ? (
                        // cell.render("Cell")
                        formatCellValue(cell.value, isGOPPercentageRow) // Format the value here
                      ) : null}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Styles>
  );
};

export default DynamicAggregationTable;
