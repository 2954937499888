import React, { useContext, useState, useMemo, useEffect } from "react";
import { Tabs, Tab, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { FilterContext } from "../../../context/FilterContext";
import { fetchReportData } from "../../../api/reportAPI/index";
import "./ReportViewer.css"; // Import the CSS for styles
import DynamicAggregationTable from "./v2_MIS";
import reportConfigurations from "./ReportConfig";

const ReportViewer = () => {
  const { filters, reportName, activeTab } = useContext(FilterContext); // Get filters from context
  const [reportData, setReportData] = useState([]); // Main report data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [currentDepartment, setCurrentDepartment] = useState(0);

  // Determine the report type based on activeTab or other logic
  const reportType = activeTab === "daily" ? "daily" : "monthly";

  // Reset `reportData` when the "clearReportData" event is dispatched
  useEffect(() => {
    const handleClearData = () => setReportData([]);
    window.addEventListener("clearReportData", handleClearData);

    return () => {
      window.removeEventListener("clearReportData", handleClearData);
    };
  }, []);

  const fetchAllData = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchReportData(filters, reportName, reportType, true); // Fetch all rows
      setReportData(data.data); // Store the full dataset
      // setInitialLoadComplete(true); // Mark initial load as complete
    } catch (err) {
      setError("Failed to fetch all data");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const exportPivotTable = async () => {
    if (!reportData || reportData.length === 0) {
      alert("No data available for export!");
      return;
    }

    try {
      const response = await axios.post(
        "http://127.0.0.1:8000/mis-reports/export-pivot/",
        { raw_data: reportData },
        { responseType: "blob" } // Ensure response is treated as a file
      );

      // Create a download link for the exported file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "pivot_table.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error exporting pivot table:", error);
      alert("Failed to export pivot table.");
    }
  };

  // Get the current configuration for the report type
  const currentConfig = reportConfigurations[reportName];

  // Function to group data by department
  const groupedData = useMemo(() => {
    if (!reportData.length || reportName !== "departmentalGroup") return {};
    return groupDataByDepartment(reportData, "DEPT_NAME");
  }, [reportData, reportName]);

  const departmentNames = useMemo(
    () => Object.keys(groupedData),
    [groupedData]
  );

  const handleTabChange = (event, newValue) => {
    setCurrentDepartment(newValue);
  };

  if (!currentConfig) {
    return <div>Invalid report type.</div>;
  }

  const selectedDepartmentData =
    departmentNames.length > 0
      ? groupedData[departmentNames[currentDepartment]]
      : [];

  // Adjust the column group based on activeTab
  const adjustedConfig = {
    ...currentConfig,
    columnGroup:
      activeTab === "monthly"
        ? ["FY_YEAR", "MMM"]
        : ["FY_YEAR", "MMM", "CYCLE_DATE"], // Use default for daily
  };

  return (
    <div className="report-viewer">
      {/* Fetch Report Button */}
      <button className="fetch-report-btn" onClick={fetchAllData}>
        Fetch Report
      </button>

      {/* Export Pivot Button */}
      <button
        className="export-pivot-btn"
        onClick={exportPivotTable}
        disabled={!reportData.length}
      >
        Export Pivot Table
      </button>

      {/* Loading Spinner */}
      {loading && (
        <div className="loading-spinner">
          <CircularProgress />
          <Typography variant="body1" sx={{ marginLeft: "1rem" }}>
            Loading report data...
          </Typography>
        </div>
      )}

      {/* Error Message */}
      {error && <div className="error-message">{error}</div>}

      {/* Message when no report data is available */}
      {!loading && !error && reportData.length === 0 && (
        <Typography variant="h6" color="textSecondary">
          No report data available for the selected filters.
        </Typography>
      )}

      {/* Conditionally Render Content Based on `reportName` */}
      {!loading && !error && reportData.length > 0 && (
        <>
          {reportName === "departmentalGroup" ? (
            departmentNames.length > 0 && (
              <>
                {/* Tabs for each department */}
                {/* Scrollable Tabs for each department */}
                <Tabs
                  value={currentDepartment}
                  onChange={handleTabChange}
                  aria-label="Department Tabs"
                  variant="scrollable" // Enable scrollable tabs
                  scrollButtons="auto" // Show scroll buttons when needed
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      justifyContent: "space-between", // Distribute tabs evenly
                    },
                    "& .MuiTab-root": {
                      textTransform: "none", // Keep tab labels in normal case
                      minWidth: "120px", // Set a minimum width for each tab
                      fontSize: "1rem", // Adjust font size for better visibility
                      padding: "10px 16px", // Add padding for better click area
                    },
                  }}
                >
                  {departmentNames.map((deptName, index) => (
                    <Tab
                      label={deptName}
                      key={deptName}
                      sx={{
                        backgroundColor:
                          index === currentDepartment ? "#e0e0e0" : "inherit", // Highlight active tab
                        borderRadius: "4px", // Add rounded corners
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // Change color on hover
                        },
                      }}
                    />
                  ))}
                </Tabs>

                {/* Render DynamicAggregationTable for the selected department */}
                <DynamicAggregationTable
                  reportData={selectedDepartmentData}
                  rowGroup={adjustedConfig.rowGroup}
                  rowGroupSortKeys={adjustedConfig.rowGroupSortKeys}
                  aggregatedValue={adjustedConfig.aggregatedValue}
                  aggregationType={adjustedConfig.aggregationType}
                  title={departmentNames[currentDepartment]} // Department Name as Title
                  subTitle={adjustedConfig.subTitle}
                  columnGroup={adjustedConfig.columnGroup}
                  reportName={reportName}
                />
              </>
            )
          ) : (
            <DynamicAggregationTable
              reportData={reportData}
              rowGroup={currentConfig.rowGroup}
              rowGroupSortKeys={currentConfig.rowGroupSortKeys}
              aggregatedValue={currentConfig.aggregatedValue}
              aggregationType={currentConfig.aggregationType}
              title={reportData?.[0]?.BU_NAME || "Unknown Business Unit"}
              subTitle={currentConfig.subTitle}
              columnGroup={currentConfig.columnGroup}
              reportName={reportName}
            />
          )}
        </>
      )}
    </div>
  );
};

// Group data by the specified field
const groupDataByDepartment = (data, departmentField) => {
  return data.reduce((acc, item) => {
    const deptName = item[departmentField];
    if (!acc[deptName]) acc[deptName] = [];
    acc[deptName].push(item);
    return acc;
  }, {});
};

export default ReportViewer;
