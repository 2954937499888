import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { FilterContext } from "../../../context/FilterContext";
import { fetchDistinctValues } from "../../../api/reportAPI/index";
import { Tab, Tabs, Box } from "@mui/material";
import "./GlobalFilter.css";
import { components } from "react-select";

// Custom Option Component for checkboxes in React Select dropdown
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null} // Prevent default onChange behavior, handle select separately
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const GlobalFilters = () => {
  const {
    filters,
    setFilters,
    clearFilters,
    activeTab,
    setActiveTab,
    reportName,
  } = useContext(FilterContext);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab === 0 ? "daily" : "monthly"); // Map MUI tab index to tab name
    clearFilters(); // Clear filters

    // Emit an event to clear report data (rename the variable to avoid conflict)
    const clearEvent = new CustomEvent("clearReportData");
    window.dispatchEvent(clearEvent);
  };

  const [fyYearOptions, setFyYearOptions] = useState([]);
  const [buNameOptions, setBuNameOptions] = useState([]);
  const [mmmOptions, setMmmOptions] = useState([]);
  const [cycleDateOptions, setCycleDateOptions] = useState([]);
  const [deptNameOptions, setDeptNameOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Option to handle Select All functionality
  const selectAllOption = {
    value: "selectAll",
    label: "Select All",
    isSelectAll: true,
  };
  const formatFiscalYear = (fyYear) => {
    if (!fyYear) return "";
    const startYear = `20${fyYear.toString().slice(0, 2)}`;
    const endYear = `20${fyYear.toString().slice(2)}`;
    return `${startYear}-${endYear}`;
  };

  const formatMonth = (month) => {
    if (!month) return "";
    const [year, monthNumber] = month.split("-");
    const date = new Date(`${year}-${monthNumber}-01`);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${monthNames[date.getMonth()]}-${year.slice(2)}`;
  };

  // Fetch distinct values for business unit and fiscal year
  useEffect(() => {
    setLoading(true);
    fetchDistinctValues("", "", reportName, activeTab)
      .then((data) => {
        setBuNameOptions(
          data.bu_name
            .map((bu) => ({ value: bu, label: bu }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        setFyYearOptions(
          data.fy_year
            .map((year) => ({ value: year, label: year }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
      })
      .catch(() => alert("Failed to fetch distinct values"))
      .finally(() => setLoading(false));
  }, [reportName, activeTab]);

  useEffect(() => {
    if (
      (reportName === "fbConsolidated" || reportName === "departmentalGroup") &&
      filters.bu_name
    ) {
      // Clear the existing department options before fetching
      setDeptNameOptions([]);
      fetchDistinctValues("bu_name", filters.bu_name, reportName, activeTab)
        .then((data) => {
          setDeptNameOptions(
            data.dept_name.map((dept) => ({ value: dept, label: dept }))
          );
        })
        .catch(() => alert("Failed to fetch department data"));
    }
  }, [filters.bu_name, reportName, activeTab]);

  // Handle Business Unit selection change
  const handleBuSelectChange = (selectedOption) => {
    setFilters({ ...filters, bu_name: selectedOption?.value, fy_year: "" });
    setMmmOptions([]);
    setCycleDateOptions([]);
    fetchFiscalYearData(selectedOption?.value);
  };

  // Fetch fiscal year data based on Business Unit
  const fetchFiscalYearData = (buName) => {
    setLoading(true);
    fetchDistinctValues("bu_name", buName, reportName, activeTab)
      .then((data) => {
        setFyYearOptions(
          data.fy_year
            .map((year) => ({ value: year, label: year }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
      })
      .catch(() => alert("Failed to fetch fiscal year data"))
      .finally(() => setLoading(false));
  };

  // Handle Fiscal Year selection change
  const handleFySelectChange = (selectedOption) => {
    setFilters({ ...filters, fy_year: selectedOption?.value, mmm: [] });
    setMmmOptions([]);
    setCycleDateOptions([]);
    fetchMonthData(selectedOption?.value);
  };

  // Fetch month data based on Fiscal Year
  const fetchMonthData = (fyYear) => {
    setLoading(true);
    fetchDistinctValues("fy_year", fyYear, reportName, activeTab)
      .then((data) => {
        setMmmOptions(
          data.mmm
            .map((month) => ({ value: month, label: month }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
      })
      .catch(() => alert("Failed to fetch month data"))
      .finally(() => setLoading(false));
  };

  // Handle Month selection change (with Select All option)
  const handleMmmSelectChange = (selectedOptions) => {
    let selectedMonths = [];

    if (selectedOptions.some((option) => option.value === "selectAll")) {
      selectedMonths = mmmOptions.map((option) => option.value);
      setFilters({ ...filters, mmm: selectedMonths });
    } else {
      selectedMonths = selectedOptions.map((option) => option.value);
      setFilters({ ...filters, mmm: selectedMonths });
    }

    fetchCycleDateData(selectedMonths);
  };

  // Fetch cycle dates based on selected months
  const fetchCycleDateData = (months) => {
    // if (months.length === 0) {
    if (months.length === 0 || activeTab === "monthly") {
      setCycleDateOptions([]);
      return;
    }

    setLoading(true);
    fetchDistinctValues("mmm", months, reportName, activeTab)
      .then((data) => {
        setCycleDateOptions(
          data.cycle_date
            .map((date) => ({ value: date, label: date }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
      })
      .catch(() => alert("Failed to fetch cycle dates"))
      .finally(() => setLoading(false));
  };

  // Handle Cycle Date selection change (with Select All option)
  const handleCycleDateSelectChange = (selectedOptions) => {
    if (activeTab === "monthly") {
      // Skip the cycle date logic if it's on the Monthly tab
      return;
    }
    const selectedCycleDates = selectedOptions
      ? selectedOptions
          .filter((option) => option.value !== "selectAll")
          .map((option) => option.value)
      : [];

    if (selectedOptions.some((option) => option.value === "selectAll")) {
      setFilters({
        ...filters,
        cycle_date: cycleDateOptions.map((option) => option.value),
      });
    } else {
      setFilters({ ...filters, cycle_date: selectedCycleDates });
    }
  };
  const handleDeptSelectChange = (selectedOption) => {
    if (
      selectedOption &&
      selectedOption.some((option) => option.value === "selectAll")
    ) {
      // Select All logic (if necessary)
      const allDeptValues = deptNameOptions.map((option) => option.value);
      setFilters({ ...filters, dept_name: allDeptValues });
    } else {
      const selectedDepts = selectedOption
        ? selectedOption.map((option) => option.value)
        : [];
      setFilters({ ...filters, dept_name: selectedDepts });
    }
  };

  // Clear all filters
  const handleClearFilters = () => {
    clearFilters();
  };

  // Custom styling for select dropdown
  // Custom styling for select dropdown
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 40,
      fontSize: "14px",
      borderRadius: "8px",
      padding: "5px 10px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    }),
    valueContainer: (base) => ({
      ...base,
      display: "flex",
      maxWidth: "100%",
      overflowX: "hidden",
      height: "40px",
      whiteSpace: "nowrap",
    }),
    multiValue: (base) => ({
      ...base,
      maxWidth: "100%",
      backgroundColor: "#E8E8E8",
      borderRadius: "12px",
      padding: "4px 8px",
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontSize: "12px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#007bff",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    }),
  };

  return (
    <div className="filters-container">
      {/* Tabs for switching between Daily and Monthly */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginBottom: "16px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Tabs
          value={activeTab === "daily" ? 0 : 1}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Daily" />
          <Tab label="Monthly" />
        </Tabs>
      </Box>
      {/* Filters */}

      <div className="filters">
        {/* Business Unit */}

        <div className="filter">
          <label>Business Unit</label>
          <Select
            options={buNameOptions}
            value={
              filters.bu_name
                ? { value: filters.bu_name, label: filters.bu_name }
                : null
            }
            onChange={handleBuSelectChange}
            placeholder="Select Business Unit"
            isLoading={loading}
            className="select-dropdown"
            styles={customStyles}
          />
        </div>

        {/* Fiscal Year */}
        <div className="filter">
          <label>Fiscal Year</label>
          <Select
            // options={fyYearOptions}
            options={fyYearOptions.map((year) => ({
              value: year.value,
              label: formatFiscalYear(year.value), // Apply the formatting here
            }))}
            value={
              filters.fy_year
                ? {
                    value: filters.fy_year,
                    label: formatFiscalYear(filters.fy_year),
                  }
                : null
            }
            onChange={handleFySelectChange}
            placeholder="Select Fiscal Year"
            isLoading={loading}
            className="select-dropdown"
            styles={customStyles}
          />
        </div>

        {/* Month (shared by both Daily and Monthly) */}
        <div className="filter">
          <label>Month</label>
          <Select
            isMulti
            // options={[selectAllOption, ...mmmOptions]}
            options={[selectAllOption, ...mmmOptions].map((option) => ({
              ...option,
              label:
                option.value === "selectAll"
                  ? option.label
                  : formatMonth(option.value),
            }))}
            value={filters.mmm.map((month) => ({
              value: month,
              label: month === "selectAll" ? "Select All" : formatMonth(month),
            }))}
            onChange={handleMmmSelectChange}
            placeholder="Select Month"
            isLoading={loading}
            className="select-dropdown"
            styles={customStyles}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
          />
        </div>
        {/* Cycle Date (Daily Tab Only) */}
        {activeTab === "daily" && (
          <div className="filter">
            <label>Cycle Date</label>
            <Select
              isMulti
              options={[selectAllOption, ...cycleDateOptions]}
              value={filters.cycle_date.map((date) => ({
                value: date,
                label: date,
              }))}
              onChange={handleCycleDateSelectChange}
              placeholder="Select Cycle Date"
              isLoading={loading}
              className="select-dropdown"
              styles={customStyles}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option }}
            />
          </div>
        )}

        {/* Department (where applicable) */}
        {reportName === "fbConsolidated" ||
        reportName === "departmentalGroup" ? (
          <div className="filter">
            <label>Department</label>
            <Select
              options={[selectAllOption, ...deptNameOptions]}
              isMulti
              value={deptNameOptions.filter((option) =>
                filters.dept_name.includes(option.value)
              )}
              onChange={handleDeptSelectChange}
              components={{ Option }}
              styles={customStyles}
              placeholder="Select Department"
              isLoading={loading}
              className="select-dropdown"
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
            />
          </div>
        ) : null}
      </div>

      <div className="buttons">
        <button className="clear-filters-btn" onClick={handleClearFilters}>
          Clear Filters
        </button>
      </div>
    </div>
  );
};

export default GlobalFilters;
