// component
import {
  Assessment,
  TrendingUp,
  Dashboard,
  Report,
  Analytics,
} from "@mui/icons-material";
import SvgColor from "../../../components/svg-color/SvgColor";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = ({ isAdmin }) => {
  return isAdmin
    ? [
        // {
        //   title: "Power BI Reports",
        //   path: "/dashboard/powerbi",
        //   icon: <TrendingUp />,
        // },
        {
          title: "Dashboard",
          path: "/dashboard/home",
          icon: <Dashboard />,
        },
        {
          title: "MIS Reports",
          icon: <Analytics />,
          children: [
            {
              title: "Fund Flow",
              path: "/mis/fund-flow",
            },
            {
              title: "Summary",
              path: "/mis/summary",
            },
            {
              title: "Profit & Loss",
              path: "/mis/profit-&-loss",
            },
            {
              title: "FnB consolidated",
              path: "/mis/fnb-consolidated",
            },
            {
              title: "Departmental Report",
              path: "/mis/departmental-report",
            },
          ],
        },

        // {
        //   title: "MIS Reports",
        //   path: "/dashboard/report",
        //   icon: <Analytics />,
        // },
        // {
        //   title: "Mock Dashboard",
        //   path: "/dashboard/home", // Main path for Mock Dashboard
        //   icon: <Dashboard />,
        //   children: [
        //     {
        //       title: "Home",
        //       path: "/dashboard/mock-dashboard/home", // Home route
        //     },
        //     {
        //       title: "Bookings",
        //       path: "/dashboard/mock-dashboard/calendar", // Calendar route
        //     },
        //     {
        //       title: "Grid Template",
        //       path: "/dashboard/mock-dashboard/grid", // Calendar route
        //     },

        //     {
        //       title: "Nationalities",
        //       path: "/dashboard/mock-dashboard/nationalities", // Calendar route
        //     },
        //   ],
        // },
        // {
        //   title: "Profile",
        //   path: "/dashboard/profile",
        //   icon: icon("ic_profile"),
        //   isAdmin: icon("ic_admin"),
        // },
        // {
        //   title: "Test Page",
        //   path: "/dashboard/testpage",
        //   icon: icon("ic_profile"),
        // },
      ]
    : [
        {
          title: "Common Page",
          path: "/dashboard/profile",
          icon: icon("ic_profile"),
        },
        {
          title: "Common Page 2 ",
          path: "/dashboard/commonpage2",
          icon: icon("ic_profile"),
        },
      ];
};

export default navConfig;
