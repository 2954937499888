import React, { createContext, useState, useEffect } from "react";

// Create the context to manage filters
export const FilterContext = createContext();

export const FilterProvider = ({ children, reportName }) => {
  // Manage the active filters
  const [filters, setFilters] = useState({
    fy_year: "",
    bu_name: "",
    mmm: [],
    cycle_date: [],
    dept_name: [], // Optional for some reports
  });

  // Manage the active tab
  const [activeTab, setActiveTab] = useState("daily"); // Default tab is "daily"

  // Define filter configurations for each tab
  const tabFilters = {
    daily: ["bu_name", "fy_year", "mmm", "cycle_date"],
    monthly: ["bu_name", "fy_year", "mmm"], // No cycle_date in monthly tab
  };

  // Define filter configurations for each report type
  const reportFilters = {
    fundFlow: ["bu_name", "fy_year", "mmm", "cycle_date"],
    summary: ["bu_name", "fy_year", "mmm", "cycle_date"],
    PnL: ["bu_name", "fy_year", "mmm", "cycle_date"],
    fbConsolidated: ["bu_name", "fy_year", "mmm", "cycle_date", "dept_name"],
    departmentalGroup: ["bu_name", "fy_year", "mmm", "cycle_date", "dept_name"],
  };

  // Reset filters and report data when the reportName changes
  useEffect(() => {
    clearFilters();
  }, [reportName]);

  // Combine the filters based on the active tab and report type
  useEffect(() => {
    clearFilters();
    const activeReportFilters = reportFilters[reportName] || [];
    const activeTabFilters = tabFilters[activeTab] || [];
    const combinedFilters = [
      ...new Set([...activeReportFilters, ...activeTabFilters]),
    ]; // Merge and deduplicate

    const updatedFilters = {};
    combinedFilters.forEach((filter) => {
      updatedFilters[filter] =
        filters[filter] ||
        (filter === "mmm" || filter === "cycle_date" ? [] : "");
    });
    if (JSON.stringify(filters) !== JSON.stringify(updatedFilters)) {
      setFilters(updatedFilters);
    }
  }, [reportName, activeTab]);

  // Clear all filters
  const clearFilters = () => {
    setFilters({
      fy_year: "",
      bu_name: "",
      mmm: [],
      cycle_date: [],
      dept_name: [],
    });
  };

  // Function to clear report data, passed to children
  const clearReportData = () => {
    const event = new CustomEvent("clearReportData");
    window.dispatchEvent(event);
  };

  // Reset filters and clear report data when the reportName changes
  useEffect(() => {
    clearFilters();
    clearReportData();
  }, [reportName]);

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
        clearFilters,
        activeTab,
        setActiveTab,
        reportName,
        clearReportData,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
