const reportConfigurations = {
  fundFlow: {
    rowGroup: ["GROUP_CLASS", "DEPT_GROUP"],
    rowGroupSortKeys: ["GROUP_CLASS_SORTKEY", "DEPT_GROUP_CODE"],
    aggregatedValue: "REP_VALUE",
    aggregationType: "sum",
    subTitle: "FUND FLOW",
    columnGroup: ["FY_YEAR", "MMM", "CYCLE_DATE"],
  },
  summary: {
    rowGroup: ["GROUP_CLASS", "LEDGER_GROUP", "LEDGER_NAME", "SUBLEDGER_NAME"],
    rowGroupSortKeys: [
      "GROUP_CLASS_SORTKEY",
      "LEDGER_GROUP_KEY",
      "LEDGER_CODE",
      "SUBLEDGER_CODE",
    ],
    aggregatedValue: "REP_VALUE",
    aggregationType: "sum",
    subTitle: "SUMMARY",
    columnGroup: ["FY_YEAR", "MMM", "CYCLE_DATE"],
  },
  PnL: {
    rowGroup: ["DEPT_GROUP", "DEPT_NAME", "GROUP_CLASS"],
    rowGroupSortKeys: [
      "DEPT_GROUP_CODE",
      "DEPT_NAME_CODE",
      "GROUP_CLASS_SORTKEY",
    ],
    aggregatedValue: "REP_VALUE",
    aggregationType: "sum",
    subTitle: "P&L",
    columnGroup: ["FY_YEAR", "MMM", "CYCLE_DATE"],
  },
  fbConsolidated: {
    rowGroup: ["GROUP_CLASS", "LEDGER_NAME", "SUBLEDGER_NAME"],
    rowGroupSortKeys: [
      // "DEPT_GROUP_CODE",
      "GROUP_CLASS_SORTKEY",
      "LEDGER_CODE",
      "SUBLEDGER_CODE",
    ],
    aggregatedValue: "REP_VALUE",
    aggregationType: "sum",
    subTitle: "FnB Consolidated",
    columnGroup: ["FY_YEAR", "MMM", "CYCLE_DATE"],
  },
  departmentalGroup: {
    rowGroup: [
      // "DEPT_GROUP",
      // "DEPT_NAME",
      "GROUP_CLASS",
      "LEDGER_NAME",
      "SUBLEDGER_NAME",
    ],
    rowGroupSortKeys: [
      // "DEPT_GROUP_CODE",
      // "DEPT_NAME_CODE",
      "GROUP_CLASS_SORTKEY",
      "LEDGER_CODE",
      "SUBLEDGER_CODE",
    ],
    aggregatedValue: "REP_VALUE",
    aggregationType: "sum",
    subTitle: "Departmental Report",
    columnGroup: ["FY_YEAR", "MMM", "CYCLE_DATE"],
  },
};

export default reportConfigurations;
